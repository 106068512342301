import Skill from "../model/Skill";

const skillsModule = {
	state: {
		skills: {
			programmingLanguages: [
				new Skill("Kotlin", 5),
				new Skill("Java", 5),
				new Skill("Typescript", 4),
				new Skill("Swift", 4),
				new Skill("Python", 3),
				new Skill("C#", 3),
				new Skill("PHP", 3, "Privat"),
				new Skill("Adobe ColdFusion", 2)
			],
			frameworkOrLibs: [
				new Skill("Android", 5),
				new Skill("Spring Boot", 5),
				new Skill("Angular", 4),
				new Skill("JBoss EAP", 4),
				new Skill("iOS", 4),
				new Skill("Jetpack Compose", 4),
				new Skill("Swift UI", 4),
				new Skill("Rabbit MQ", 4),
				new Skill("Kotlin Multiplatform", 4),
				new Skill("Kotlin Coroutines", 4),
				new Skill("RxJava", 4.5),
				new Skill("Rest API", 5),
				new Skill("Android Jetpack", 4.5),
				new Skill("Android Room", 4.5),
				new Skill("Android WorkManager", 4),
				new Skill("Dagger Hilt", 4),
				new Skill("Dagger 2", 4),
				new Skill("Koin", 4),
				new Skill("Retrofit", 4),
				new Skill("Crashlytics", 4),
				new Skill("Postman", 5),
				new Skill("Swagger / OpenAPI", 4),
				new Skill("Moshi", 4),
				new Skill("JSON", 5),
				new Skill("XML", 4),
				new Skill("Vue.js", 4, "Privat"),
				new Skill("Vuetify", 4, "Privat"),
				new Skill("Tensorflow Lite", 3.5),
				new Skill("SQL", 4.5),
				new Skill("SQLite", 4.5),
				new Skill("NodeJS", 4, "Privat"),
				new Skill("NPM", 4),
				new Skill(".NET Core", 3),
				new Skill("DevExpress", 3),
				new Skill("Graph QL", 3.5, "Privat"),
				new Skill("Websocket", 3.5),
			],
			softwareArchitecture: [
				new Skill("Clean Architecture", 5),
				new Skill("Microservices", 5),
				new Skill("SOLID Principles", 5),
				new Skill("Modular Programming", 4.5),
				new Skill("Dependency Injection", 5),
				new Skill("UML", 4),
				new Skill("MVVM", 5),
				new Skill("MVC", 4.5),
				new Skill("ORM", 4.5),
				new Skill("Strategy Pattern", 4),
			],
			businessKnowledge: [
				new Skill("Rechnungswesen", 4),
				new Skill("Buchhaltung", 4),
				new Skill("Journalverwaltung", 4),
				new Skill("Druck Steuerung", 4.5),
				new Skill("Bluetooth", 4),
				new Skill("SAFe (Scaled Agile Framework)", 4),
				new Skill("Scrum", 4),
				new Skill("Drucker", 4.5),
				new Skill("Confluence", 4),
				new Skill("Jira", 4),
				new Skill("SAM (Secure Access Module)", 3.5),
			],
			testing: [
				new Skill("JUnit 4/5", 4.5),
				new Skill("Robolectric", 4.5),
				new Skill("Jest", 5),
				new Skill("Cucumber", 3.5),
				new Skill("Espresso", 4),
				new Skill("UI Automator", 4),
				new Skill("Google Truth Assertions", 4.5),
				new Skill("Mockito Kotlin", 5)
			],
			devOps: [
				new Skill("Gradle", 4),
				new Skill("AWS", 4),
				new Skill("Maven", 3),
				new Skill("Docker", 3, "Privat"),
				new Skill("Kubernetes", 3, "Privat"),
				new Skill("Jenkins", 3),
				new Skill("Artifactory", 4),
				new Skill("GitHub", 4),
				new Skill("GitLab", 4),
				new Skill("Firebase", 3),
			],
		},
	},
};

export default skillsModule;
